/*
 * File: googletagmanager
 * File Created: Monday, 18th November 2019 5:12:38 pm
 * Author: Guillaume Pilot (guillaume.pilot@gridpocket.com)
 * -----
 * Last Modified: Monday, 18th November 2019 5:12:38 pm
 * Modified By: Guillaume Pilot (guillaume.pilot@gridpocket.com>)
 * -----
 * GridPocket SAS Copyright (C) 2019 All Rights Reserved
 * This source is property of GridPocket SAS.
 * Please email contact@gridpocket.com for more information.
 */

function getMeta(name) {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i += 1) {
    if (metas[i].getAttribute('name') === name) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
}

const consentCookie = (!Cookies.get('consent_analytics_cookies') || Cookies.get('consent_analytics_cookies') === "true");
const gtmID = getMeta('GTMID');
if (gtmID && gtmID !== 'undefined' && gtmID !== '' && consentCookie) {
  ((w, d, s, l, i) => {
    w[l] = w[l] || [];
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
    j.async = true;
    j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', gtmID);
}
